import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Layout/Layout';
import TalentDetail from './TalentDetail';
import '../../contentful/fragments';

export default props => {
  const data = {
    ...props.data,
    allTalents: props.data.allTalents.nodes,
  };
  return (
    <Layout>
      <TalentDetail {...props} data={data} />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!, $slug: String!) {
    talent: contentfulTalentDetailPage(
      node_locale: { eq: $locale }
      sluggedName: { eq: $slug }
    ) {
      ...talentDetailPageFragment
    }

    allTalents: allContentfulTalentDetailPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        sluggedName
        category
        image {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
