export const shuffle = array => {
  let currentIndex = array.length;
  let temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
};

export const sortByKey = (arr, key) => {
  let sorted = [...arr];
  if (typeof arr[0][key] === 'number') {
    sorted.sort((a, b) => {
      return a[key] - b[key];
    });
  } else {
    sorted.sort((a, b) => {
      let nameA = a[key].toUpperCase(); // ignore upper and lowercase
      let nameB = b[key].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  }
  return sorted;
};

export const getPrettyURL = url => {
  let l = new URL(url);
  return `${l.host.startsWith('www') ? l.host.substr(4) : l.host}${
    l.pathname !== '/' ? l.pathname : ''
  }`;
};
