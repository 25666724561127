import React, { Fragment } from 'react';
import './TalentDetail.scss';
import {
  DetailGrid,
  ListWithHeadline,
  FullViewportStage,
  Carousel,
  CarouselScreen,
  Footer,
  DetailPageFooterNav,
  SlideTransition,
} from '../../components/index';
import { parse } from 'query-string';
import { Link } from 'gatsby';
import { MoonLoader } from 'react-spinners';
import BackGroundImageStage from '../../components/BackGroundImageStage/BackGroundImageStage';
import { getPrettyURL } from '../../helper/helper';
import VideoStage from '../../components/VideoStage/VideoStage';
import ScrollDown from '../../components/ScrollDown/ScrollDown';
import ScrollTransition from '../../components/Carousel/Transitions/ScrollTransition';

const AboveTheFold = ({
  goToScreen,
  talentName,
  image,
  showreel,
  PlayShowreel,
}) => (
  <BackGroundImageStage fixedBgImage img={image}>
    <FullViewportStage spaced centered>
      <h2 className="typography__header2">{talentName}</h2>
      {showreel && <PlayShowreel />}
      <ScrollDown onClick={() => goToScreen(1)} />
    </FullViewportStage>
  </BackGroundImageStage>
);

class TalentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.talentData = props.data.talent;
    this.state = {
      data: this.talentData,
      showSpinner: !this.talentData,
      videoIsOpen: false,
    };
  }

  extractVimeoID(showreel) {
    if (showreel && showreel.indexOf('vimeo') > -1) {
      return showreel;
    } else {
      return false;
    }
  }

  render() {
    const { showSpinner } = this.state;
    const { footerData } = this.props.pageContext;
    const { location } = this.props;
    const data = this.state.data || {};
    const {
      image,
      talentName,
      shortDescription,
      favSong,
      favMovie,
      favAnimal,
      favDrink,
      favColor,
      favPlace,
      website,
      showreel,
    } = data;

    const { allTalents } = this.props.data;

    const filteredTalents =
      location.state && location.state.filteredItems
        ? location.state.filteredItems
        : allTalents;

    const {
      back,
      playShowreel,
      favSong: favSongLabel,
      favAnimal: favAnimalLabel,
      favColor: favColorLabel,
      favMovie: favMovieLabel,
      favDrink: favDrinkLabel,
      favPlace: favPlaceLabel,
      shortDescription: shortDescriptionLabel,
    } = this.props.pageContext.text.talentDetail;
    const footerNavLabels = this.props.pageContext.text.detailPagesFooterNav;

    let PlayShowreel;

    const isVimeo = this.extractVimeoID(showreel);

    if (isVimeo) {
      PlayShowreel = () => (
        <a
          className={'typography__link'}
          onClick={() => this.setState({ videoIsOpen: true })}
        >
          {playShowreel}
        </a>
      );
    } else {
      PlayShowreel = () => (
        <a
          href={showreel}
          title={playShowreel}
          className={'typography__link'}
          target={'_blank'}
          rel="noopener noreferrer"
        >
          {playShowreel}
        </a>
      );
    }

    const PageContent = ({ goToScreen, scrollToTop }) => {
      return (
        <Fragment>
          <FullViewportStage centered black regularOverflow>
            <div className={'detailGrid'}>
              <DetailGrid.Container
                label={shortDescriptionLabel}
                noPaddingTop
                top={
                  <DetailGrid.Row className={'container__row--breakOnMobile'}>
                    <DetailGrid.Cell>
                      <ListWithHeadline
                        headline={favSongLabel}
                        list={favSong}
                      />
                      <ListWithHeadline
                        headline={favMovieLabel}
                        list={favMovie}
                      />
                    </DetailGrid.Cell>
                    <DetailGrid.Cell>
                      <ListWithHeadline
                        headline={favAnimalLabel}
                        list={favAnimal}
                      />
                      <ListWithHeadline
                        headline={favDrinkLabel}
                        list={favDrink}
                      />
                    </DetailGrid.Cell>
                    <DetailGrid.Cell>
                      <ListWithHeadline
                        headline={favColorLabel}
                        list={favColor}
                      />
                      <ListWithHeadline
                        headline={favPlaceLabel}
                        list={favPlace}
                      />
                    </DetailGrid.Cell>
                  </DetailGrid.Row>
                }
                bottom={
                  <Fragment>
                    <DetailGrid.Row>
                      <DetailGrid.Cell>
                        <p
                          className={
                            'typography__paragraph typography__paragraph--limited'
                          }
                        >
                          {shortDescription.shortDescription}
                        </p>
                      </DetailGrid.Cell>
                    </DetailGrid.Row>
                    <DetailGrid.Row className={'container__row--breakOnMobile'}>
                      <DetailGrid.Cell>
                        {website ? (
                          <a
                            href={website}
                            target={'_blank'}
                            rel="noopener noreferrer"
                          >
                            <ListWithHeadline
                              headline={'Website'}
                              list={getPrettyURL(website)}
                              listIsLink
                            />
                          </a>
                        ) : (
                          <ListWithHeadline headline={'Website'} list={'-'} />
                        )}
                      </DetailGrid.Cell>
                      <DetailGrid.Cell>
                        {showreel ? (
                          <a
                            href={showreel}
                            target={'_blank'}
                            rel="noopener noreferrer"
                          >
                            <ListWithHeadline
                              headline={'Showreel'}
                              list={getPrettyURL(showreel)}
                              listIsLink
                            />
                          </a>
                        ) : (
                          <ListWithHeadline headline={'Showreel'} list={'-'} />
                        )}
                      </DetailGrid.Cell>
                      <DetailGrid.Cell />
                    </DetailGrid.Row>
                  </Fragment>
                }
              />
            </div>
          </FullViewportStage>
        </Fragment>
      );
    };

    const LastScreen = ({ scrollToTop, goToScreen, allTalents }) => {
      const goToTop = async () => {
        await scrollToTop(150);
        goToScreen(0);
      };
      return (
        <FullViewportStage black centered className={'last-screen'}>
          <DetailPageFooterNav
            labels={footerNavLabels}
            allItems={allTalents}
            currentItem={this.talentData}
            categoryQuery={parse(this.props.location.search).category}
          />
          <Footer footerData={footerData} onArrowClick={() => goToTop()} />
        </FullViewportStage>
      );
    };
    const Page = ({ allTalents }) => {
      return (
        <Fragment>
          {this.state.data ? (
            <Carousel location={location}>
              <CarouselScreen transition={SlideTransition}>
                <AboveTheFold
                  PlayShowreel={PlayShowreel}
                  showreel={showreel}
                  talentName={talentName}
                  image={image}
                />
              </CarouselScreen>
              <CarouselScreen transition={ScrollTransition} scrollable={true}>
                <PageContent />
              </CarouselScreen>
              <CarouselScreen transition={ScrollTransition} scrollable={true}>
                <LastScreen allTalents={allTalents} />
              </CarouselScreen>
            </Carousel>
          ) : (
            <Fragment>
              <FullViewportStage centered>
                <MoonLoader size={60} color={'white'} loading={showSpinner} />
              </FullViewportStage>
              <Footer footerData={footerData} />
            </Fragment>
          )}
        </Fragment>
      );
    };

    return (
      <div>
        {isVimeo && (
          <VideoStage
            openVideo={this.state.videoIsOpen}
            onClose={() => this.setState({ videoIsOpen: false })}
            videoId={this.extractVimeoID(showreel)}
            quality={'720p'}
          />
        )}
        <Link
          to={
            this.props.location.pathname
              .split('/')
              .filter(Boolean) // Remove trailing slash if found
              .slice(0, -1)
              .join('/') + `/${this.props.location.search}`
          }
        >
          <div className={'projectDetail__iconLink scrollable'}>
            <img alt={'back button'} src={'assets/icons/back.svg'} />
            <p
              className={'typography__paragraph'}
              onClick={() => this.setState({ videoIsOpen: false })}
            >
              {back}
            </p>
          </div>
        </Link>
        <Page allTalents={filteredTalents} />
      </div>
    );
  }
}

export default TalentDetail;
